.presetDialog {
  padding:50px;
}
.presetDesc {
  height:100px;
  padding:20px;
}

.presetButton {
  margin: 3px !important;
}