.textinput {
  width: 100%;
  margin-bottom: 6px;
  padding-bottom: 5px;
  margin-top: 2px;
  height:2em;

  &:last-child {
    border-bottom: none;
  }
}
.textinput input{
  width:min(25em, 50%);
  float:left;
  height:1.9em;
  border:1px solid black;
  border-radius: 5px;
}