* {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  outline: none;
}

body {
  font-family: 'open_sansregular', 'Segoe UI', Tahoma, sans-serif;
  font-size: 12px;
  color: #303030;
  background-color: lightgray;
  margin: 0px;
  padding: 0px;
  width: 100%;
  overflow-x: hidden;
}

.dropdown {
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: 20px;
  background: #fff;
  background-image: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.06));
  background-image: -moz-linear-gradient(top, transparent, rgba(0, 0, 0, 0.06));
  background-image: -o-linear-gradient(top, transparent, rgba(0, 0, 0, 0.06));
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.06));
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  width:99%;
  margin-bottom: 5px;
  border: 1px solid;
  border-color: #ccc #ccc #ccc;
  border-radius: 3px;

  &:before, &:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 7px;
    right: 7px;
    width: 0;
    height: 0;
    border: 4px dashed;
    border-color: #888 transparent;
    pointer-events: none;
  }

  &:before {
    border-bottom-style: solid;
    border-top: none;
    margin-top: -2px;
  }

  &:after {
    margin-top: 5px;
    border-top-style: solid;
    border-bottom: none;
  }

  .dropdown__select {
    position: relative;
    overflow:visible;
    width: 100%;
    margin-top:0px;
    padding-bottom:0px;
    padding: 1px 8px 6px 5px;
    height: 23px;
    line-height: 20px;
    font-size: 12px;
    color: #62717a;
    text-shadow: 0 1px white;
    /* Fallback for IE 8 */
    background: #f2f2f2;
    /* "transparent" doesn't work with Opera */
    background: rgba(0, 0, 0, 0) !important;
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;

    &:focus {
      z-index: 3;
      width: 90%;
      color: #4fa619;
      outline: 0px solid #49aff2;
      outline: 0px solid -webkit-focus-ring-color;
      outline-offset: 5px;

      height:25px;
    }

    & > option {
      margin: 3px;
      padding: 6px 8px;
      text-shadow: none;
      background: #f2f2f2;
      border-radius: 3px;
      cursor: pointer;
    }
  }

  &.dark {
    background: #3e403f;
    background-image: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
    background-image: -moz-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
    background-image: -o-linear-gradient(top, transparent, rgba(0, 0, 0, 0.4));
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4));

    border-color: #111 #0a0a0a black;
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), 0 1px 1px rgba(0, 0, 0, 0.2);
    color:#a6a6a6;
    text-shadow:0px 1px rgba(0, 0, 0, 0.25);

    &:before {
      border-bottom-color: #aaa;
    }

    &:after {
      border-top-color: #aaa;
    }

    .dropdown__select {
      color: #a6a6a6;
      text-shadow: 0 1px black;
      background: #444;
      padding-right: 20px;
    }

    .dropdown__select:focus {
      color: #fff;
    }

    .dropdown__select > option {
      background: #56ab1a;
      text-shadow: 0 1px rgba(0, 0, 0, 0.4);
    }
  }
}

.main {
  padding: 0px 0px 0 0px;
  height: calc(100% - 7px);

  .main__header {
    background: #3d3f3e;
    position: fixed;
    width: 100%;
    z-index: 1;
    overflow: hidden;
  }

  .main__bar {
    height:45px;
    @media only screen and (max-width: 600px) {
      height:90px;
    }
    width:100%;
    background-image: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.15));

    .star {
      display:block;
      width:5px;
      height:5px;
      border-radius:50%;
      background:#FFF;
      position:absolute;
      transform-origin:100% 0;
      animation:star-ani 4s linear infinite;
      -webkit-animation:star-ani 5s linear infinite;
      box-shadow:0 0 5px 5px rgba(255,255,255,.3);
      opacity:0;
      z-index:2;
    }
    .star:after {
      content:'';
      display:block;
      top:0px;
      left:4px;
      border:0px solid #fff;
      border-width:0px 90px 2px 90px;
      border-color:transparent transparent transparent rgba(255,255,255,.3);
      transform:rotate(-45deg) translate3d(1px,3px,0);
      box-shadow:0 0 1px 0 rgba(255,255,255,.1);
      transform-origin:0% 100%;
    }
    @keyframes star-ani {
      0% {
        opacity:0;
        transform:scale(0) translate3d(0,0,0);
      }
      20% {
        opacity:0.2;
        transform:scale(0.15) translate3d(-30px,30px,0);
      }
      40% {
        opacity:0.4;
        transform:scale(0.3) translate3d(-60px,60px,0);
      }
      60% {
        opacity:0.4;
        transform:scale(0.45) translate3d(-90px,90px,0);
      }
      80% {
        opacity:0.6;
        transform:scale(0.65) translate3d(-125px,125px,0);
      }
      90% {
        opacity:0.5;
        transform:scale(0.8) translate3d(-135px,135px,0);
      }
      100% {
        opacity:0.2;
        transform:scale(0.85) translate3d(-155px,155px,0);
      }
    }

  }

  .main__logo {
    //background: url('images/icon_128.png');
    position: relative;
    float: left;
    height: 40px;
    z-index: 0;
    background-repeat: no-repeat;
    width: 95px;
    background-position: left center;
    background-size: contain;
    margin-left: 0px;
    margin-top: 0px;

    @media only screen and (max-width: 600px) {
      margin-left: 15px;
    }
  }

  .main__settings {
    float: right;
    margin-top: 12px;
    width: 100px;
    margin-right: 10px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 0px;
      background: #3d3f3e;

      & > * {
        flex: 1;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 6px;
      }

      a {
        margin: 0px;
        width: 100%;
      }
    }
  }

  .main__connect {
    float: right;
    margin-top: 12px;
    width: 80px;
    margin-right: 10px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 0px;
      background: #3d3f3e;

      & > * {
        flex: 1;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 6px;
      }

      a {
        margin: 0px;
        width: 100%;
      }
    }
  }

  .button.button--dark {
    a,
    button {
      text-align: center;
      border-radius: 3px;
      border: 1px solid var(--color-primary);
      color: #fff;
      float: right;
      font-family: "open_sansbold", Arial;
      font-size: 12px;
      text-shadow: 0px 1px rgb(0 0 0 / 25%);
      display: block;
      cursor: pointer;
      transition: all ease 0.2s;
      margin: 0px;
      width: 100%;
      line-height: 21px;
      padding: 0px;
      color: #a6a6a6;
      background: #3e403f;
      border-color: #111 #0a0a0a black;
      background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4));

      &:active {
        color: white;
      }
    }

    a {
      margin-top: 8px;
      width: 118px;
    }
  }

  
  .button.button--connect {
    a,
    button {
      text-align: center;
      border-radius: 3px;
      border: 1px solid var(--color-primary);
      color: #fff;
      float: right;
      font-family: "open_sansbold", Arial;
      font-size: 12px;
      display: block;
      cursor: pointer;
      transition: all ease 0.2s;
      margin: 0px;
      width: 100%;
      line-height: 21px;
      padding: 0px;
      background: var(--color-primary);
      border-color: #111 #0a0a0a black;

      &:active {
        color: white;
      }
    }

    a {
      margin-top: 8px;
      width: 118px;
    }
    .disabled {
      color:gray;
    }
  }


  a {
    text-decoration: none;
    color: #000;
    font-family: 'open_sanssemibold', Arial;
  }

  a:hover {
    text-decoration: none;
  }

  a.disabled {
    pointer-events: none;
    cursor: default;
    color: #999;
  }

  .message-positive {
    color: green;
  }

  .message-emphasis {
    color: yellow;
  }

  .message-negative {
    color: red;
  }

  input[type="number"]::-webkit-inner-spin-button {
      opacity: 1; /* required for chromium 33+ beta */
      margin-left: 5px;
  }

  .clear-both {
    clear: both;
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }

  .hidden {
    display: none;
  }

  .half {
    width: 50%;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .spacer {
      padding-left: 7px;
      padding-right: 7px;
      width: calc(100% - 14px);
      float: left;
  }
}

.default-btn {
  width: 100%;
  position: relative;
  margin-top: 0px;

  &button,
  button,
  a {
    cursor: pointer;
    padding: 5px 0px 5px 0px;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid var(--color-tertiary);
    color: var(--color-tertiary);
    font-family: 'open_sanssemibold', Arial;
    font-size: 12px;
    line-height: 13px;
    display: block;
    width: 100%;
    transition: all ease 0.2s;
    text-decoration:none;
    font-weight: bold;
  }

  button:hover,
  a:hover {
    background-color: var(--color-tertiary);
    color: #fff;
    text-shadow: 0px 1px rgba(0, 0, 0, 0.25);
    transition: all ease 0.2s;
    text-decoration:none;
  }

  button[disabled],
  button[disabled]:hover,
  a.disabled,
  a.disabled:hover {
    background-color: #fff;
    text-shadow: none;
    border: 1px solid #ccc;
    color: #ccc;
    cursor: default;
  }

  button:active,
  a:active {
    background-color: var(--color-tertiary);
    transition: all ease 0.0s;
    box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.35);
  }



  .red {
    color: red;
  }
}

.content_wrapper {
  padding: 20px;
  position: relative;
  margin-bottom: 50px;

  @media only screen and (max-width: 600px) {
    margin-bottom: 120px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 13px;
  }
}

#tab-landing {
  .content_wrapper {
    margin-bottom: 0px;
  }
}

.mobile-show {
  display: none;
}
@media only screen and (max-width: 600px) {
  .mobile-hide {
    display: none;
  }

  .mobile-show {
    display: block;
  }
}

.backdrop {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
