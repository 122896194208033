#content .tab #firmware-selector {
  .checkbox label {
    flex: 1;
  }

  .note {
    margin-bottom: 7px;
  }
}

#firmware-selector {
  .checkbox {
    float: none;
    width: auto;
    margin-left: 0px;
    margin-bottom: 20px;
    margin-top: 0;
    padding-bottom: 0px;

    span:last-child {
      margin-left: 5px;
    }
  }

  .center-wrapper {
    width: 50%;
    margin: auto;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .red {
    color: red;
  }

  .default-btn {
    margin-bottom: 10px;
  }

  .default-btn:last-child {
    margin-bottom: 0px;
  }

  .alert {
    table {
      margin-top: 5px;
      margin-bottom: 5px;
      font-weight: bold;
      td {
        padding-right: 5px;
      }
    }

    a {
      color: white;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
