.flash {
  .flash__wrapper {
    display: flex;
  }

  .flash__common {
    margin-right: 10px;
    flex-basis: 0;
    flex-grow: 1;
  }

  .flash__individual {
    margin-left: 10px;
    flex-basis: 0;
    flex-grow: 1;
  }

  @media screen and (max-width: 768px) {
    .flash__wrapper {
      flex-direction: column;
    }

    .flash__common,
    .flash__individual {
      margin: 0px;
    }
  }
}
