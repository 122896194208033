.esc-melody-wrapper {
  width: 50%;

  .esc-melody {
    flex: 1;
    margin: 10px;
    position: relative;

    header {
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-bottom: 1px solid silver;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .default-btn {
        width: auto;
        display: flex;

        button {
          margin-left: 10px;
          width: 100px;
        }

        button.accepted {
          background: var(--color-tertiary);
          color: white;

          &[disabled] {
            cursor: default;
            opacity: 0.5;
          }
        }
      }

      .index {
        flex: 1;
        h4 {
          padding-top: 5px;
          font-size: 1.17em;
        }
      }
    }

    .editor-wrapper {
      border: 1px solid silver;
      padding: 5px;
      display: flex;

      & > * {
        width: 100%;
        height: 115px;
        font-family: monospace;
        overflow-y: auto;

        mark {
          background: #FFCCCB;
        }
      }

      &[disabled] {
        color: #909090;
      }

      &.playing {
        mark {
          background: #CCFFCB;
        }
      }
    }
  }
}
