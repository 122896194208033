#motor-control-wrapper {
  max-width: 800px;
  width: 100%;
  // margin-bottom: 65px;

  .gui-box {
    .spacer-box {
      p {
        font-weight: normal;
        padding-bottom: 10px;
      }
    }

    .line-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 3px;

      &>* {
        flex: 1;
        padding: 0px;
        margin-bottom: 7px;
      }

      .battery-state {
        font-weight: bold;

        &.danger {
          font-weight: bold;
          color: red;
        }
      }
    }
  }

  .rc-slider {
    margin-left: 8px;
    height: 30px;
    margin-top:20px;
  }

  .rc-slider-tooltip {
    .rc-slider-tooltip-content {
      margin-bottom: -15px;
    }

    .rc-slider-tooltip-arrow {
      display: none;
    }
    .rc-slider-tooltip-inner {
      background-color: transparent;
      color: black;
      font-weight: normal;
      box-shadow: none;
    }
  }

  .rc-slider-track {
    background: var(--color-primary);
  }

  .rc-slider-handle {
    margin-top: -7px;
    width: 19px;
    height: 19px;
    border: 0px;
    background: var(--color-primary);
  }

  .rc-slider-disabled {
    background: transparent;

    .rc-slider-handle {
      background: #cccccc;
    }
  }

  .checkbox label {
    flex: 0 0 10px !important;
  }

  .checkbox {
    span {
      margin-left: 5px;
    }
  }

  #slider-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #single-slider,
    #master-slider {
      flex: 1;
      margin-right: 30px;
    }

    .slider,
    #master-slider {
      .input-range {
        float: none;
        margin-left: 8px;
      }
    }
    #master-slider {
      h3 {
        padding-bottom: 20px;
      }
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column-reverse;
      align-items: flex-start;

      #single-slider,
      #master-slider {
        width: 90%;
      }

      .slider {
        h3 {
          padding-top: 10px;
        }
      }
    }

    .paddingbox{
      width:10px;
      height:20px;
    }

  }





  .row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .column-wrapper {
    display: flex;
    flex: 1;
    margin-right: 30px;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: flex-start;

    .column-wrapper {
      width: 90%;
    }
    
  }

  .motor-telem-info{
    font-weight:bold !important;
    .rpm{
      font-weight:bold !important;
      display:inline-block;
      width:40px;
      text-align:right;
    }
  }

}
