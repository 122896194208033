.input-range {
  padding-left: 3px;
  height: 20px;
  line-height: 20px;
  text-align: left;
  margin: 0 14px 0 0;
  font-size: 12px;
  font-weight: normal;
  display: inline-block;
}

.input-range__label--min,
.input-range__label--max {
    display: none;
}

.input-range__label--value {
    margin-left: 1rem;
}

.input-range__slider {
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.input-range__track--active {
  background: var(--color-primary);
}

.number {
  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .disabled {
    width: 43px;
    padding: 0px 5px;
    background-color: #ececec;
  }

  span.label {
    margin-left: 3px;
    float: left;
  }
}
