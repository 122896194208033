.esc {
  progress {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-tertiary);
    border-radius: 5px;
  }

  progress::-webkit-progress-bar {
    background: #fff;
    border-radius: 5px;
  }

  progress::-webkit-progress-value {
    background: var(--color-tertiary);
  }

  .firmware-dump {
    margin-top: 7px;
  }
}
