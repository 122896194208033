.number-text {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .disabled {
    width: 43px;
    padding: 0px 5px;
    background-color: #ececec;
  }

  span.label {
    margin-left: 3px;
    float: left;
  }
}
