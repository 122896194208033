#tab-landing {
  min-height: 100%;
  overflow: hidden;

  p {
    padding-bottom: 10px;
  }

  .line-1 {
    margin-bottom: 20px;
  }

  .install-wrapper {
    position: absolute;
    top: 17px;
    right: 60px;
    height: 0;
    overflow: hidden;

    @media only screen and (min-width: 1660px) {
      &.active {
        animation: slide 1s ease 0.5s forwards;
      }
    }
  }

  @keyframes slide {
    from {height: 0;}
    to {height: 150px;}
  }

  .install {
    max-width: 400px;
    padding: 10px;
    background: rgba(211, 211, 211, 0.25);

    .description {
      text-align: center;
    }

    .default-btn {
      margin: auto;
      button {
        max-width: 200px;
      }
    }
  }

  .melody-editor-button {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;

    button {
      max-width: 200px;
    }
  }

  .firmware-logo-bar {
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      width: 50%;
    }
  }

  a.discord-link {
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 20px;

    img.discord {
      height: 50px;
    }
  }

  .content_wrapper {
    padding: 0;
    overflow-y: auto;
  }

  .content_top {
    min-height: 140px;
    background: var(--color-primary);
    padding: 20px;
    display: flex;
    max-width: 700px;
    margin:50px auto;

    @media only screen and (max-width: 600px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .content_mid {
    //background-color: #eaeaea;
    overflow: hidden;
    display: flex;
    padding-bottom: 45px;
    padding-top: 20px;

    @media only screen and (max-width: 991px) {
      & {
        flex-direction: column;
      }
    }

    .summary-section {
      margin-top: 20px;

      &:first-child {
        margin-top: 0px;
      }

      h3 {
        padding-bottom: 5px;
        margin-bottom: 10px;
        border-bottom: 1px solid silver;
      }

      section {
        display: flex;

        p {
          padding-bottom: 10px;
        }

        p:last-of-type {
          padding-bottom: 0px;
        }

        img {
          width: 128px;
          height: 128px;
          margin-right: 15px;
        }
      }
    }

    a,
    a:hover {
      text-decoration: underline;
    }

    & > div {
      flex: 1
    }

    .column {
      .wrap {
        padding: 15px;
        padding-bottom: 0px;
        padding-top: 0px;
      }
    }

    h2 {
        margin-bottom: 5px;
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
        margin-bottom: 2px;
    }
  }

  .logowrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    color: white;
    font-size: 14px;
    font-family: 'open_sanslight', Arial;

    a {
      color: white;
      text-decoration: underline;
    }

    img {
      width: 420px;
      margin: 5px;
    }

    span {
      font-size: 22px;
      font-family: 'open_sanslight', Arial;
    }
  }

  .text1, .text2, .text3 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: normal;
    font-family: 'open_sansregular', Arial;
    font-size: 14px;
    line-height: 20px;
  }

  .text1 .wrap {
      margin-left: 0px;
  }

  .text3 ul,
  .text2 ul {
    margin-top: 10px;

    li {
      line-height: 24px;
      margin-left: 20px;
      list-style: disc;
    }
  }
}

.homemain{
  padding:50px max(20px, calc(100% / 2 - 300px)); 
  padding-top:0;
}

.upgrading{
  color:#a2aa37;
  margin-bottom: 20px;
  display: inline-block;
  border:2px dashed rgb(185, 187, 87);
  border-radius: 0.3em;
  padding: 2em;
  width: calc( 100% - 4em - 4px);
}

.upgrading p{
  font-size: 1em;
}

.docs{
  color:black;
  margin-bottom: 20px;
  display: inline-block;
  border:1px dotted #229fda;
  border-radius: 0.3em;
  padding: 2em;
  width: calc( 100% - 4em - 4px);
}

.docs p{
  font-size: 1em;
}

.logo__span {
  background: url('../App/images/icon_128.png');
  position: relative;
  float: left;
  height: 100px;
  z-index: 0;
  background-repeat: no-repeat;
  width: 95px;
  background-position: left center;
  background-size: contain;
  margin-left: 0px;
  margin-top: 0px;

  @media only screen and (max-width: 600px) {
    margin-left: 15px;
  }
}


.targetsTable {
  border:1px solid black;
  border-collapse: collapse;
  width:100%;
  vertical-align:top;
  th{
    text-align: center;
    font-weight: bold;
    border:1px solid black;
    padding:1px;
  }
  td{
    border:1px solid black;
    padding:1px;
  }
}