.select {
   margin-bottom: 5px;
   width: 100%;

   select {
     flex: 0 0 198px;
   }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
