.changelog {
  width: 250px;
  height: 100%;
  position: fixed;
  right: -245px;
  top: 137px;
  height: calc(100% - 137px - 21px);

  @media only screen and (max-width: 600px) {
    top: 170px;
    height: calc(100% - 170px - 21px);
  }

  .changelog__wrapper {
    height: 100%;
    padding: 0 20px;
    border-left: 5px solid var(--color-secondary);
    overflow-y: auto;
    display: none;
  }

  &.expanded {
    right: 0px;
    background: white;

    .changelog__wrapper {
      display: block;
    }
  }

  .button {
    transform: rotate(270deg);
    top: 50px;
    right: 212px;
    position: absolute;
    background: var(--color-secondary);
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    display: block;
    padding: 5px 10px;
    width: 70px;
    text-align: center;
    color: white;
    cursor: pointer;
  }

  .changelog__title {
    margin: 20px 0;
    font-size: 16px;
  }

  .changelog__content {
    line-height: 17px;

    span {
      display: block;
      font-weight: bold;
      padding-bottom: 5px;
      border-bottom: 1px solid #ddd;
    }

    ul {
      margin: 5px 0 20px 10px;

      li {
        font-weight: normal;
        margin-bottom: 5px;
      }
    }

    p {
      margin-bottom: 20px;
    }
  }
}
