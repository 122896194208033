@import '../../variables.scss';

.button-bar {
  z-index: 1;
  position: fixed;
  bottom: 21px;
  width: 100%;
  background-color: #EFEFEF;
  box-shadow: rgba(0, 0, 0, 0.10) 0 -3px 8px;
  padding: 10px 0;
  overflow: hidden;
  border-top: 1px solid #F9F9F9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    padding-bottom: 0px;
    padding-top: 8px;
  }

  & > div {
    display: flex;

    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    &.buttons-right {
      align-items: flex-end;
      flex-direction: row-reverse;
    }

    &.buttons-left {
      justify-content: flex-start;
      margin-left: 20px;

      @media only screen and (max-width: 600px) {
        margin-left: 10px;
      }
    }

    &.mobile-show {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;

    & > div {
      justify-content: flex-start;

      &.buttons-bottom,
      &.buttons-left,
      &.buttons-right {
        margin-bottom: 8px;
        margin-left: 5px;
        margin-right: 5px;

        .generic-button {
          flex: 1;
          margin-left: 2px;
          margin-right: 2px;
          button {
            width: 100%;
          }
        }

        &.mobile-show {
          display: flex;

          button {
            margin-bottom: 8px;
          }
        }
      }

      &:first-child {
        margin-bottom: 0px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .generic-button button {
      margin-right: 10px;
    }
  }
}
