:root {
  /*
  --color-primary: #125684;
  --color-secondary: #2a84c4;
  --color-tertiary: #252177;
  */

  --color-primary: #478380;
  --color-secondary: #4ab3ad;
  --color-tertiary: #2f5553;
}
