.info-wrapper-wrapper {
  margin-left: 15px;
  min-width:200px;
}

.info-wrapper,
#content .tab .info-wrapper {
  position: relative;
  margin: 0px;
  margin-left: 5px;
  display: inline-block;

  span {
    margin: 0px;
  }

  span.info-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: lightgray;
    display: inline-block;
    color: black;
    text-align: center;
    line-height: 15px;
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
    cursor: pointer;
    z-index: -1;
  }

  .tooltip {
    max-width: 200px;
  }
}
